<template>
    <div class="page-factory-login">
        <img
            class="title"
            :src="ossHost + 'img/title.png'"
            alt=""
        >

        <div :class="{'input-box': true, 'err': errText}">
            <input
                type="text"
                maxlength="10"
                v-model="name"
                @input="change"
                placeholder="请输入您的姓名"
            >
            <div class="err-msg">仅支持输入中文</div>
        </div>

        <div class="footer">
            <div
                :class="{ 'btn': true, 'pass': name && !errText }"
                @click="submitClick"
            >进入</div>
            <img
                :src="ossHost + 'img/logo.png'"
                alt=""
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "FactoryLogin",
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            name: "",
            errText: ""
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const { yType } = vm.$route.query
        const uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (name && fType) {
            if (
                ["lexing", "ruiyin", "yingwo", "lansong", "wangbo", "haopai"].indexOf(fType) > -1
            ) {
                vm.$router.replace({
                    path: `/factory/index?yType=${yType || ''}`
                });
                vm.$localStorage.setItem('uuid', uuid)
                return;
            }
            vm.$router.replace({
                path: "404"
            });
        } else {
            if (!uuid) {
                vm.$localStorage.setItem(USER_NAME, "");
                vm.$localStorage.setItem("uuid", "");
                vm.$router.replace({
                    path: "404"
                });
            }
        }
    },
    methods: {
        change() {
            const vm = this;
            const han = /^[\u2E80-\u9FFF]+$/;
            let err = "";
            if (!vm.name) {
                err = "请输入您的名字~";
            }
            if (!han.test(vm.name)) {
                err = "请输入中文~";
            }
            vm.errText = err;
        },

        submitClick() {
            const vm = this;
            const { USER_NAME } = vm.$config.keys;
            if (vm.errText || !vm.name) {
                return;
            }
            const uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
            const fType = vm.$strTool.filterFactoryType(uuid);
            const { yType } = vm.$route.query
            vm.$localStorage.setItem(USER_NAME, vm.name);
            vm.$route.query.uuid && vm.$localStorage.setItem('uuid', uuid);

            if (
                ["lexing", "ruiyin", "yingwo", "lansong", "wangbo", "haopai"].indexOf(fType) > -1
            ) {
                vm.$router.replace({
                    path: `/factory/index?yType=${yType || ''}`
                });
                return;
            }
            vm.$router.replace({
                path: "404"
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.page-factory-login {
    width: 100%;
    height: 100%;
    background: @blank;
    overflow: hidden;
    position: relative;

    .title {
        display: block;
        height: 1.1rem;
        margin: 0.2rem auto;
    }

    .input-box {
        position: absolute;
        left: 0.5rem;
        right: 0.5rem;
        border-bottom: 1px solid @black;
        bottom: 50%;
        &.err {
            border-bottom-color: @textColor2;
            input {
                color: @textColor2;
            }
            .err-msg {
                display: block;
            }
        }

        input {
            border: 0;
            display: block;
            width: 100%;
            text-align: center;
            font-size: 0.3rem;
            padding: 0.1rem 0;
            outline: none;
            &::placeholder {
                color: @bgColor1;
            }
        }

        .err-msg {
            position: absolute;
            bottom: -0.3rem;
            left: 0;
            right: 0;
            text-align: center;
            color: @textColor2;
            font-size: 0.15rem;
            display: none;
        }
    }

    .footer {
        position: absolute;
        text-align: center;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 0.1rem;
        img {
            display: block;
            height: 0.35rem;
            margin: 0 auto;
        }

        .btn {
            width: 2.35rem;
            height: 0.6rem;
            line-height: 0.6rem;
            color: @blank;
            border-radius: 0.3rem;
            background: @bgColor1;
            text-align: center;
            font-size: 0.2rem;
            box-shadow: 0px 2px 10px 0px @bgColor1;
            margin: 0 auto 0.5rem;
            &.pass {
                background: @themeColor;
            }
        }
    }
}
</style>
